<template>
  <v-container fluid>
    <v-row>
      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <h3>{{ $t('masterData.aboutMe') }}</h3>

        <app-labeled-data label="company" :value="creditorDetails.company.name" />
        <app-labeled-data label="tradeName" :value="creditorDetails.company.tradeName" />
        <app-labeled-data
          :label="`${$t('street')}, ${$t('house')}`"
          :value="creditorDetails.company.streetAndHouse"
        />
        <app-labeled-data
          :label="`${$t('zip')}, ${$t('city')}`"
          :value="`${creditorDetails.company.zip} ${creditorDetails.company.city || '-'}`"
        />

        <app-labeled-data
          class="mt-8"
          label="contactPerson"
          :value="`${creditorDetails.company.salutation} ${
            creditorDetails.company.contactName || '-'
          }`"
        />
        <app-labeled-data label="landlineNumber" :value="creditorDetails.company.phone" />
        <app-labeled-data label="mobileNumber" :value="creditorDetails.company.mobile" />
        <app-labeled-data label="emailAddress" :value="creditorDetails.company.email" />

        <app-labeled-data
          class="mt-8"
          label="registerNumber"
          :value="creditorDetails.legal.registerNumber"
        />
        <app-labeled-data
          label="placeOfAuthority"
          :value="creditorDetails.legal.placeOfAuthority"
        />
        <app-labeled-data label="proprietor" :value="creditorDetails.legal.proprietor" />
      </v-col>

      <v-col :cols="isMobile ? 12 : 6" class="tab__col">
        <h3>{{ $t('masterData.myBankDetails') }}</h3>

        <app-labeled-data label="accountOwner" :value="creditorDetails.sepa.accountOwner" />
        <app-labeled-data label="iban" :value="creditorDetails.sepa.iban" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'TabAbout',

  props: {
    creditorDetails: {
      type: Object,
      required: true
    }
  }
};
</script>
